.content__service {
  & .service__list {
    counter-reset: number 0;

    & > li {
      padding-top: 96px;
      margin-top: -96px;

      &:not(:last-child) {
        margin-bottom: 160px;
      }

      &:nth-child(odd) {
        & .service__wrap {
          background-position: 100% 0;
          padding-left: 45.6%;
        }

        & .service__heading {
          right: 48px;
          text-align: right;
        }

        & .service__txt {
          padding: 0 48px 48px 0;
        }
      }

      &:nth-child(even) {
        & .service__wrap {
          padding-right: 45.6%;
        }

        & .service__heading {
          left: 48px;
          text-align: left;
        }

        & .service__txt {
          padding: 0 0 48px 48px;
        }
      }

      &:nth-child(1) {
        & .service__wrap {
          background-image: url('../../images/img_service01.jpg');
        }
      }

      &:nth-child(2) {
        & .service__wrap {
          background-image: url('../../images/img_service02.jpg');
        }
      }

      &:nth-child(3) {
        & .service__wrap {
          background-image: url('../../images/img_service03.jpg');
        }
      }
    }
  }

  & .service__wrap {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 80px;
    box-sizing: border-box;
  }

  & .service__heading {
    position: absolute;
    top: -96px;
    text-shadow: 2px 2px 2px var(--wht), -2px -2px 2px var(--wht),
      2px -2px 2px var(--wht), -2px 2px 2px var(--wht);

    & b {
      display: block;
      margin-bottom: 8px;
      font-size: var(--heading-large);
    }

    & p {
      font-size: var(--text-mediumSmall);
    }

    &::before {
      counter-increment: number 1;
      content: '0' counter(number);
      text-shadow: none;
      font-size: var(--heading-superLarge);
      color: var(--wht);
    }
  }

  & .service__txt {
    font-size: var(--text-medium);

    & p {
      &:not(:last-child) {
        margin-bottom: 1.5em;
      }
    }

    & ul {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
    }
  }
}

@media screen and (--mobile-viewport) {
  .content__service {
    & .service__list {
      & > li {
        &:not(:last-child) {
          margin-bottom: 80px;
        }

        & .service__heading {
          position: static;
          width: 100%;
          word-break: break-all;
        }

        &:nth-child(odd) {
          & .service__wrap {
            background-size: 220%;
            padding: 0;
          }

          & .service__heading {
            text-align: left;
          }

          & .service__txt {
            padding: 0;
          }
        }

        &:nth-child(even) {
          & .service__wrap {
            background-position: 100%;
            background-size: 220%;
            padding: 0;
          }

          & .service__txt {
            padding: 0;
          }
        }

        &:nth-child(1) {
          & .service__wrap {
            background-image: none;
          }

          & .service__txt {
            &::before {
              background-image: url('../../images/img_service01.jpg');
              background-size: 220%;
            }
          }
        }

        &:nth-child(2) {
          & .service__wrap {
            background-image: none;
          }

          & .service__txt {
            &::before {
              background-image: url('../../images/img_service02.jpg');
              background-position: 100%;
              background-size: 220%;
            }
          }
        }

        &:nth-child(3) {
          & .service__wrap {
            background-image: none;
          }

          & .service__txt {
            &::before {
              background-image: url('../../images/img_service03.jpg');
              background-position: bottom left;
              background-size: 220%;
            }
          }
        }
      }
    }

    & .service__txt {
      &::before {
        content: '';
        display: block;
        background-repeat: no-repeat;
        margin-bottom: 16px;
        width: 100%;
        height: 240px;
      }

      & ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        & li {
          padding: 0 16px;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

.header {
  position: relative;
  z-index: 2;
  background-color: var(--beg);
  padding: 8px 0 24px;
  text-align: center;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.3);

  & .header__logo {
    position: relative;
    padding-bottom: 24px;
    margin: auto;
    width: 100%;
    max-width: 920px;

    & .logo_image {
      margin-bottom: 8px;
    }

    & p {
      font-size: var(--text-small);
    }

    & > a {
      position: absolute;
      top: -8px;
      right: 0;
    }
  }

  & .header__menuBtn {
    display: none;
  }

  & .header__navi {
    & ul {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & li {
      padding: 0 16px;
    }

    & a {
      font-size: var(--text-medium);
      font-weight: bold;
    }
  }
}

@media screen and (--mobile-viewport) {
  .header {
    position: relative;
    padding: 0;
    box-shadow: none;

    & .header__wrap {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      background-color: var(--beg);
      padding: 8px;
      box-shadow: 2px 0 6px rgba(0, 0, 0, 0.3);
    }

    & .header__logo {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;

      & .logo_image {
        margin: 0;
        width: 180px;

        & img {
          width: 100%;
        }
      }

      & p {
        display: none;
      }

      & > a {
        display: none;
      }
    }

    & .header__menuBtn {
      display: block;
      background-color: transparent;
      border: none;
    }

    & .header__navi {
      position: absolute;
      top: 52px;
      left: 0;
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.9);
      width: 100%;
      transform-origin: top center;
      transform: translateY(-260px);
      transition: 0.3s;

      & ul {
        display: flex;
        flex-direction: column;
      }

      & li {
        border-bottom: 1px solid #ddd;
        padding: 0;
        width: 100%;
      }

      & a {
        display: block;
        padding: 12px 0;
      }

      &.is-open {
        transform: translateY(0);
      }
    }
  }
}

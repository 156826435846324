.content__slider {
  padding: 0 0 48px !important;
  margin: auto;
  width: 100%;
  max-width: 1000px;

  & .slider__listItem {
    position: relative;
    background-color: var(--beg);
    border-bottom: 1px solid var(--light-gry);
    padding: 48px 0;
    height: 390px;
    overflow: hidden;

    &.slider__listItem--gameIllust {
      & .slider__txt {
        left: 0;
        border-left: none;
        border-color: #b775ea;

        & a {
          background-color: #b775ea;
          color: var(--wht);
        }

        &::before {
          color: #b775ea;
        }
      }

      & .slider__img {
        right: 0;
      }

      &::after {
        right: 264px;
        background-image: url('../../images/ico_pen.png');
      }
    }

    &.slider__listItem--commerce {
      & .slider__txt {
        right: 0;
        border-right: none;
        border-color: #ed8132;

        & a {
          background-color: #ed8132;
          color: var(--wht);
        }

        &::before {
          color: #ed8132;
        }
      }

      &::after {
        left: 264px;
        background-image: url('../../images/ico_flower.png');
      }
    }

    &.slider__listItem--restaurant {
      & .slider__txt {
        left: 0;
        border-left: none;
        border-color: #e0a720;

        & a {
          background-color: #e0a720;
          color: var(--wht);
        }

        &::before {
          color: #e0a720;
        }
      }

      & .slider__img {
        right: 0;
      }

      &::after {
        right: 264px;
        background-image: url('../../images/ico_cafe.png');
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      display: block;
      background-repeat: no-repeat;
      border-radius: 50%;
      margin: auto;
      width: 164px;
      height: 164px;
    }
  }

  & .slider__txt {
    position: absolute;
    top: 72px;
    z-index: 2;
    background-color: var(--wht);
    border-width: 1px;
    border-style: solid;
    padding: 48px 24px 24px;
    text-align: center;
    width: 100%;
    height: 310px;
    max-width: 335px;
    box-sizing: border-box;

    & b {
      display: block;
      margin-bottom: 16px;
      font: bold var(--heading-medium) / 1 Arial Black, sans-serif;
    }

    & p {
      text-align: left;
      font-size: var(--text-small);
    }

    & a {
      position: absolute;
      right: 0;
      bottom: -24px;
      left: 0;
      display: block;
      margin: auto;
      width: 230px;
      height: 48px;
      font-size: var(--text-mediumLarge);
      line-height: 48px;

      &::after {
        content: '→';
      }
    }

    &::before {
      content: '0' attr(data-number);
      position: absolute;
      z-index: 2;
      top: -24px;
      right: 0;
      left: 0;
      font: bold var(--heading-large) / 1 serif;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: -50px;
      right: 0;
      left: 0;
      z-index: 1;
      background-image: radial-gradient(
        circle farthest-side,
        var(--beg) 65%,
        transparent 100%
      );
      margin: auto;
      width: 100px;
      height: 100px;
    }
  }

  & .slider__img {
    position: absolute;
    top: 48px;
    z-index: 1;
  }

  & .swiper-pagination {
    & .swiper-pagination-bullet-active {
      background-color: var(--dark-gry);
    }
  }
}

.content__groupCompany {
  margin-bottom: 80px;

  & .groupCompany__list {
    display: flex;
    justify-content: space-between;
  }

  & .groupCompany__listItem {
    display: flex;
    flex-direction: column-reverse;
    background-color: var(--light-gry);
    padding: 24px;
    width: 49%;
    max-width: 430px;
    box-sizing: border-box;
  }

  & .groupCompany__txt {
    & h3 {
      text-align: center;
      font-size: var(--heading-ultraSmall);
      font-weight: bold;
    }

    & p {
      font-size: var(--text-mediumSmall);
    }
  }

  & .groupCompany__img {
    margin-bottom: 16px;
    text-align: center;
  }
}

.content__news {
  & .news__listItem {
    border-bottom: 1px dashed #000;
    padding: 8px 0;

    & time {
      margin-right: 16px;
    }
  }
}

@media screen and (--mobile-viewport) {
  .content__slider {
    & .slider__listItem {
      &::after {
        background-image: none !important;
      }
    }
  }
  .content__groupCompany {
    & .groupCompany__list {
      flex-wrap: wrap;
      justify-content: center;
    }

    & .groupCompany__listItem {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    & .groupCompany__img {
      & img {
        max-width: 100%;
      }
    }
  }
}

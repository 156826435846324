.content__contact {
  & p {
    margin-bottom: 24px;
    text-align: center;
  }

  & .contact__list {
    margin-bottom: 24px;

    & div {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    & dt {
      margin-bottom: 4px;
      font-size: var(--text-midium);

      & span {
        font-size: var(--text-small);
        color: #f00;
      }
    }

    & dd {
      & span {
        display: block;
      }

      & .your-recipient {
        position: relative;
        width: 200px;

        & select {
          border: 1px solid var(--gry);
          padding: 4px 8px;
          width: 100%;
          font-size: var(--text-small);
          box-sizing: border-box;
        }

        &::after {
          content: '▲';
          position: absolute;
          top: 0;
          right: 8px;
          bottom: 0;
          display: flex;
          align-items: center;
          margin: auto;
          font-size: var(--text-small);
          line-height: 1;
          pointer-events: none;
          transform: rotate(180deg);
        }
      }

      & input,
      & textarea {
        border: 1px solid var(--gry);
        padding: 4px 8px;
        width: 100%;
        font-size: var(--text-small);
        box-sizing: border-box;
      }
    }
  }

  & .contact__btn {
    text-align: center;

    & input {
      background-color: var(--pnk);
      width: 200px;
      height: 57px;
      font-size: var(--text-mediumLarge);
      font-weight: bold;
      color: var(--wht);
    }
  }
}

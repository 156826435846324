.content__press {
  & .press__list {
    border-top: 1px solid #ddd;
    padding-top: 24px;
  }

  & .press__listItem {
    border-bottom: 1px solid #ddd;
    padding-bottom: 24px;

    & a {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  & .press__detail {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 48px;
    text-decoration: none;
  }

  & .press__txt {
    flex: 1;
    display: grid;
    grid-template-rows: 40px 1fr;
    grid-template-columns: 120px 1fr;

    & time {
      grid-row: 1;
      grid-column: 1;
      display: block;
      font-size: var(--text-large);
    }

    & h2,
    & h3 {
      grid-row: 1;
      grid-column: 2;
      margin: 0;
      text-align: left;
      font: bold var(--text-large) / 1.5 'Hiragino Kaku Gothic ProN',
        'ヒラギノ角ゴ ProN W3', Arial, 'メイリオ', Meiryo, sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > p,
    & > div {
      grid-row: 2;
      grid-column: 1/3;
    }
  }

  & .press__img {
    padding-right: 32px;
    width: 240px;

    & img {
      width: 100%;
    }
  }

  & .press__naviList {
    display: flex;
    justify-content: space-between;

    & .press__naviListItem--prev {
      & a {
        &::before {
          content: '＜ ';
        }
      }
    }

    & .press__naviListItem--next {
      & a {
        &::after {
          content: ' ＞';
        }
      }
    }
  }
}

@media screen and (--mobile-viewport) {
  .content__press {
    & .press__listItem {
      & a {
        flex-direction: column-reverse;
      }
    }

    & .press__detail {
      flex-direction: column-reverse;
    }

    & .press__img {
      padding: 0;
      margin-bottom: 16px;
      width: 100%;

      & img {
        width: 100%;
      }
    }

    & .press__txt {
      grid-template-rows: 32px 1fr;
      grid-template-columns: 96px 1fr;

      & time,
      & h2,
      & h3 {
        font-size: var(--text-medium);
      }
    }

    & .press__naviList {
      flex-wrap: wrap;
    }
  }
}

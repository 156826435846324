/**
 * base
 */

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Arial,
    'メイリオ', Meiryo, sans-serif;
  color: var(--blk);
}

input,
button,
select,
textarea {
  background-color: transparent;
  border: none;
  border-radius: 0;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Arial,
    'メイリオ', Meiryo, sans-serif;
  appearance: none;
}

img {
  vertical-align: bottom;
}

a {
  color: var(--blk);
  text-decoration: none;

  & img {
    transition: 0.3s;
  }

  &:hover,
  &:focus {
    text-decoration: underline;

    & img {
      opacity: 0.7;
    }
  }
}

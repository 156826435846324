.content__recruit {
  & .recruit__listItem {
    background-color: var(--light-gry);
    padding: 24px;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  & .recruit__listDetail {
    display: flex;
    margin-bottom: 24px;
  }

  & .recruit__listTxt {
    flex: 1;

    & div {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    & dt {
      margin-bottom: 8px;
      font-size: var(--text-large);
      font-weight: bold;
    }
  }

  & .recruit__listImg {
    margin-left: 24px;
    width: 200px;

    & img {
      width: 100%;
    }
  }

  & .recruit__listLink {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--pnk);
    width: 100%;
    height: 57px;
    font-size: var(--text-mediumLarge);
    font-weight: bold;
    color: var(--wht);
    text-decoration: none;
  }
}

@media screen and (--mobile-viewport) {
  .content__recruit {
    & .recruit__listItem {
      padding: 24px 16px;

      & h2 {
        margin-bottom: 16px;
        font-size: var(--text-mediumLarge);
      }
    }

    & .recruit__listDetail {
      flex-direction: column-reverse;
    }

    & .recruit__listTxt {
      & dt {
        margin-bottom: 8px;
        font-size: var(--text-medium);
        font-weight: bold;
      }
    }

    & .recruit__listImg {
      margin: 0 auto 16px;
      text-align: center;
    }
  }
}

.content {
  flex: 1;
  padding: 18px 0 96px;

  & .content__header {
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    font: bold var(--heading-large) / 1 'Arial Black' sans-serif;
    color: var(--wht);
    text-shadow: 1px 1px 7px var(--blk);

    &.content__header--press {
      background-image: url('../../images/tit_news.jpg');
    }

    &.content__header--company {
      background-image: url('../../images/tit_campany.jpg');
    }

    &.content__header--recruit {
      background-image: url('../../images/tit_recruit.jpg');
    }

    &.content__header--service {
      background-image: url('../../images/tit_service.jpg');
    }

    &.content__header--contact {
      flex-direction: column;
      padding: 24px 24px 0;
      height: auto;
      color: var(--blk);
      text-shadow: none;
      box-sizing: border-box;

      & h1 {
        margin-bottom: 24px;
      }

      & p {
        font-size: var(--text-medium);
        font-weight: normal;
      }
    }
  }

  & .content__detail {
    padding: 80px 24px 0;
    margin: auto;
    width: 100%;
    max-width: 880px;
    font-size: 1.6rem;
    box-sizing: border-box;

    & h2 {
      margin-bottom: 24px;
      text-align: center;
      font: bold var(--heading-medium) / 1 'Arial Black' sans-serif;
    }

    &.content__detail--service {
      padding-top: 160px;
      width: 100%;
      max-width: 1000px;
    }

    &.content__detail--contact {
      padding: 40px 24px 0;
      width: 100%;
      max-width: 640px;
    }
  }

  &.content--service {
    background-color: var(--ppr);
  }

  &.content--top {
    padding-top: 0;
  }
}

@media screen and (--mobile-viewport) {
  .content {
    padding-bottom: 72px;

    & .content__header {
      font-size: var(--heading-medium);
    }

    & .content__detail {
      padding: 40px 16px 0;
      width: 100%;
      box-sizing: border-box;

      & h2 {
        font-size: var(--heading-small);
      }

      &.content__detail--service {
        padding-top: 80px;
        width: 100%;
      }
    }
  }
}

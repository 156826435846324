.content__philosophy {
  margin-bottom: 96px;

  & .philosophy__list {
    display: flex;
  }

  & .philosophy__listItem {
    display: flex;
    flex-direction: column;
    align-items: center;

    & b {
      order: 2;
      display: block;
      margin-bottom: 16px;
      text-align: center;
      font-size: 2.4rem;
      font-weight: bold;
    }

    & p {
      order: 3;
    }

    & div {
      order: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      height: 148px;
    }
  }
}

.content__company {
  margin-bottom: 96px;

  & .company__listItem {
    display: flex;

    &:not(:last-child) {
      height: 3em;
    }

    & dt {
      width: 120px;
    }

    & dd {
      & b {
        &::after {
          content: '/';
          margin: 0 4px;
        }
      }

      & ul {
        display: flex;
        flex-wrap: wrap;
      }

      & li {
        &:not(:last-child)::after {
          content: '/';
          margin: 0 4px;
        }
      }
    }
  }
}

.content__map {
  & .map__content {
    position: relative;
    padding-top: 45.45%;
    width: 100%;
    max-width: 880px;
    height: 0;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (--mobile-viewport) {
  .content__philosophy {
    margin-bottom: 56px;

    & .philosophy__list {
      flex-direction: column;

      & li {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      & b,
      & div {
        margin-bottom: 8px;
      }
    }
  }

  .content__company {
    margin-bottom: 56px;

    & .company__listItem {
      &:not(:last-child) {
        margin-bottom: 16px;
        height: auto;
      }

      & dt {
        width: 100px;
      }

      & dd {
        flex: 1;

        & ul {
          display: block;
        }

        & li {
          &:not(:last-child)::after {
            display: none;
          }
        }
      }
    }
  }

  .content__map {
    & .map__content {
      padding-top: 75%;
      width: 100%;
    }
  }
}

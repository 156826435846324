.footer {
  background-color: var(--dark-gry);
  text-align: center;

  & .footer__inner {
    position: relative;
    padding: 16px 0;
    margin: auto;
    width: 100%;
    max-width: 920px;
  }

  & .footer__logo {
    display: block;
  }

  & .footer__navi {
    & ul {
      display: flex;
      justify-content: center;
      padding: 20px 0;
    }

    & li {
      padding: 0 16px;
    }

    & a {
      font-size: var(--text-small);
      font-weight: bold;
      color: var(--wht);
    }
  }

  & .footer__copy {
    background-color: var(--gry);
    padding: 16px 0;
  }
}

@media screen and (--mobile-viewport) {
  .footer {
    & .footer__inner {
      width: 100%;
    }

    & .footer__logo {
      position: static;
    }

    & .footer__navi {
      & ul {
        flex-direction: column;
      }

      & a {
        display: block;
        padding: 0.5em 0;
        font-size: 1.6rem;
      }
    }
  }
}
